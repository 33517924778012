
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "VAutocompleteApi",

  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },

    items: {
      type: Array as PropType<Array<Record<string, string>>>,
      required: true,
    },

    label: {
      type: String as PropType<string>,
      required: true,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    itemText: {
      type: String as PropType<string>,
      default: "",
    },

    itemValue: {
      type: String as PropType<string>,
      default: "",
    },

    rules: {
      type: Array as PropType<Array<boolean | string>>,
      default: () => [],
    },
  },

  data() {
    return {
      searchInput: "",
      timeoutId: 0,
    };
  },

  computed: {
    cItemText(): string {
      if (this.itemText || this.items.length === 0) return this.itemText;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = ["nome", "descricao", "id"];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    cItemValue(): string {
      if (this.itemValue || this.items.length === 0) return this.itemValue;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = ["id", "value"];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },
  },

  watch: {
    searchInput() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        const itemText = this.items.find(
          (item) => item[this.cItemValue] === this.value
        )?.[this.cItemText];

        if (itemText === this.searchInput) return;

        this.$emit("search", this.searchInput ?? "");
      }, 1000);
    },
  },

  methods: {
    evOnInput(value?: string) {
      this.$emit("input", value ?? "");
    },
  },
});
