
import Vue, { PropType } from "vue";
import VForm from "@/lib/types/v-form";
import { maxLength, required } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import Api from "@/lib/api";
import { Rq, Table } from "@/lib/amdt";
import IAccountType from "@/lib/interfaces/account-type";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import vAutocompleteApi from "@/components/elements/v-autocomplete-api.vue";
import IGrouperAccountType from "@/lib/interfaces/account-types-bundler";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";
import ACard from "@/components/elements/a-card.vue";

export default Vue.extend({
  name: "AccountTypeMaintenance",

  components: {
    vAlertMessage,
    vAutocompleteApi,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ASelect,
    ACard,
  },

  props: {
    accountType: {
      type: Object as PropType<IAccountType>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      loadingAgrupadores: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",
      search: "",

      accountsTypes: new Array<IAccountType>(),

      agrupadores: new Array<IGrouperAccountType>(),

      dataAccountType: {
        id: "",
        tipo_conta_agrupador_id: "",
        descricao: "",
        desativado: "0",
        tipo: "",
      } as IAccountType,

      tipo: [
        { value: "0", text: "Débito" },
        { value: "1", text: "Crédito" },
        { value: "2", text: "Débito e Crédito" },
      ],
    };
  },

  watch: {
    accountType: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataAccountType = {
          ...this.accountType,
        };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataAccountType.id ? "Alterar" : "Salvar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },

    cDescricao(): (IGrouperAccountType | { id: string; descricao: string })[] {
      return [/*{ id: "", descricao: "NENHUM" },*/ ...this.agrupadores];
    },
  },

  methods: {
    required,
    maxLength,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_conta.write");

        const tblAccountType = new Table("tipo_conta");

        const obj = {
          ...this.dataAccountType,
          id: this.dataAccountType.id || "default",
        };
        tblAccountType.setColsFromObject(obj);
        tblAccountType.addRowsFromObject(obj);

        rq.addTable(tblAccountType);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um tipo de conta com esse nome";

          return;
        }

        this.ret_msg = `Tipo de conta ${
          this.dataAccountType.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataAccountType.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataAccountType = {
        id: "",
        tipo_conta_agrupador_id: "",
        descricao: "",
        desativado: "0",
        tipo: "",
      };

      this.form.resetValidation();
    },

    async getGroupsAccountsTypes(search: string) {
      try {
        this.loadingAgrupadores = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_conta_agrupador.read", {
          search,
          page: "1",
          itemsPerPage: "5",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.agrupadores =
          rsp.getTable("tipo_conta_agrupador")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingAgrupadores = false;
      }
    },
  },
});
