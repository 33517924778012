
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IAccountType from "@/lib/interfaces/account-type";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IAccountTypeBundler from "@/lib/interfaces/account-types-bundler";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "AccountTypeList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    accountType: {
      type: Object as PropType<IAccountType>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      accountTypes: new Array<IAccountType>(),
      accountTypesBundlers: new Array<IAccountTypeBundler>(),

      headers: [
        { text: "Agrupador", value: "cBundler" },
        { text: "Descrição", value: "descricao" },
        { text: "Tipo", value: "cType" },
        { text: "Status", value: "cDesativado" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAccountTypes().then();
    },

    options: {
      deep: true,
      handler() {
        this.getAccountTypes().then();
      },
    },
  },

  mounted() {
    this.getAccountTypesBundlers().then();
  },

  computed: {
    cAccountTypes(): Array<Record<string, unknown>> {
      return this.accountTypes.map((accountType) => {
        return {
          ...accountType,

          cBundler:
            this.accountTypesBundlers.find(
              (bundler) => bundler.id === accountType.tipo_conta_agrupador_id
            )?.descricao ?? accountType?.tipo_conta_agrupador_id,

          cType: (
            {
              "1": "Crédito",
              "2": "Débito",
              "3": "Débito e Crédito",
            } as Record<string, string>
          )[accountType.tipo],

          cDesativado: accountType.desativado === "0" ? "Ativo" : "Inativo",
        };
      });
    },
  },

  methods: {
    async openAccountType(id: string) {
      const accountType = this.accountTypes.find(
        (accountType) => accountType.id === id
      );

      this.$emit("update:account-type", { ...accountType });
    },

    async getAccountTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_conta.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.accountTypes = rsp.getTable("tipo_conta")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAccountTypesBundlers() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("tipo_conta_agrupador.read", {
          page: "1",
          itemsPerPage: "-1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountTypesBundlers =
          rsp.getTable("tipo_conta_agrupador")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
