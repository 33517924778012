
import Vue from "vue";
import IAccountType from "@/lib/interfaces/account-type";
import AccountTypesMaintenance from "@/components/subviews/account-types/account-types-maintenance.vue";
import AccountTypesList from "@/components/subviews/account-types/account-types-list.vue";

export default Vue.extend({
  name: "AccountTypes",

  components: { AccountTypesList, AccountTypesMaintenance },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      accountType: {} as IAccountType,
    };
  },
});
